// Single palette for project.
// For compile, run: npm run gulp -- build --app color_schemes

/*
This is file autogenerated! Don't change this file directly!
Source: frontend/color_schemes/colors.scss
*/

$colors-map: (
  'new': (
    color: #3a87ad,
    color-reverse: white,
  ),
  '0': (
    color: #f44336,
    color-reverse: white,
  ),
  '1': (
    color: #3f51b5,
    color-reverse: white,
  ),
  '2': (
    color: #4caf50,
    color-reverse: white,
  ),
  '3': (
    color: #ff9800,
    color-reverse: black,
  ),
  '4': (
    color: #e91e63,
    color-reverse: white,
  ),
  '5': (
    color: #2196f3,
    color-reverse: white,
  ),
  '6': (
    color: #8bc34a,
    color-reverse: white,
  ),
  '7': (
    color: #ff5722,
    color-reverse: white,
  ),
  '8': (
    color: #03a9f4,
    color-reverse: white,
  ),
  '9': (
    color: #cddc39,
    color-reverse: white,
  ),
  '10': (
    color: #795548,
    color-reverse: white,
  ),
  '11': (
    color: #9c27b0,
    color-reverse: white,
  ),
  '12': (
    color: #00bcd4,
    color-reverse: white,
  ),
  '13': (
    color: #ffeb3b,
    color-reverse: white,
  ),
  '14': (
    color: #9e9e9e,
    color-reverse: white,
  ),
  '15': (
    color: #673ab7,
    color-reverse: white,
  ),
  '16': (
    color: #009688,
    color-reverse: white,
  ),
  '17': (
    color: #ffc107,
    color-reverse: white,
  ),
  '18': (
    color: #607d8b,
    color-reverse: white,
  ),
);

// styles for Forms colors (used on calendar etc)
@each $label, $map in $colors-map {
  .imredi_form_#{$label} {
    background: map-get($map, color) !important;
    border-color: map-get($map, color) !important;
    color: map-get($map, color-reverse) !important;
  }

  .standard_palette_#{$label} {
    color: map-get($map, color) !important;
    background: map-get($map, color-reverse) !important;
  }
}

code.imredi_form {
    text-shadow: none;
}